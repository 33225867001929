import type { NextPage } from "next";
import MintBox from "../components/mintBox";
import Gallery from "../components/gallery";
import Head from "next/head";

const Home: NextPage = () => {
  return (
    <>
      <Head>
        <title>0xBears</title>
        <meta property="og:title" content="0xBears" />
        <meta property="og:url" content="https://0xbears.com" />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content="🐻10000 Collections of 0xBears 🐻.\r\nPure DEGEN 💛\r\nNot affiliated with any projects 🟨."
        />
        <meta property="og:image" content="/thumbnail.jpeg" />
      </Head>

      <div className="bg-amber-100">
        <div className="h-[100vh] w-full relative flex flex-col">
          {/* Header */}
          <div className="h-32 w-full pt-8 px-12 flex flex-row justify-between items-center">
            <a className="block" href=".">
              <img className="h-[100px]" src="/assets/logo.png" alt="" />
            </a>
            <div className="pl-3 text-amber-600 italic text-4xl font-bold">0xBears</div>
            <div className="flex-grow" />

            {/*  Social */}
            <div className="flex flex-row flex-nowrap gap-2">
              {[
                ["Twitter", "https://twitter.com/0xBears_nft"],
                ["Opensea", "https://opensea.io/collection/0xbear"],
                ["Etherscan", "https://etherscan.io/address/" + process.env.CONTRACT_ADDRESS ?? ""],
              ].map(([name, url], idx) => (
                <a
                  key={idx}
                  className="block px-3 py-1 rounded-md bg-amber-200 text-center flex items-center justify-center hover:bg-amber-50 transition-all transition-duration-200 text-amber-600 uppercase font-semibold"
                  href={url}
                  target="_blank"
                  rel="noreferrer"
                >
                  {name}
                </a>
              ))}
            </div>
          </div>

          {/* Content */}
          <div className="relative h-[100px] w-full flex-grow">
            {/* Gallery */}
            <Gallery images={["/assets/0.png", "/assets/1.png", "/assets/2.png", "/assets/3.png", "/assets/4.png"]} />

            {/* Blur */}
            <div className="absolute top-0 left-0 h-full w-full backdrop-blur-md" />

            <MintBox />
          </div>

          {/* Footer */}
          <div className="h-[200px] px-[100px] pb-[20px] w-full flex flex-row justify-between items-center">
            <div className="text-amber-600">
              <div>© {new Date().getFullYear()}, 0xBears</div>
              <div>Let&apos;s Fucking Go!</div>
            </div>

            <div className="h-full opacity-60">
              <img className="h-full" src="/assets/glasses.png" alt="0xglasses" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
