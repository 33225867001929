import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { init as onboardInit, useConnectWallet, useSetChain, useWallets } from "@web3-onboard/react";
import injectedModule from "@web3-onboard/injected-wallets";
import ledgerModule from "@web3-onboard/ledger";
import walletConnectModule from "@web3-onboard/walletconnect";
import coinbaseModule from "@web3-onboard/coinbase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Notify from "bnc-notify";

import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { ZeroExBears__factory } from "../contracts";
import { BigNumber, ethers } from "ethers";
import { TransactionResponse } from "@ethersproject/abstract-provider";

onboardInit({
  wallets: [injectedModule(), coinbaseModule(), walletConnectModule(), ledgerModule()],
  chains: [
    {
      id: "0x1",
      token: "ETH",
      label: "Ethereum Mainnet",
      rpcUrl: "https://mainnet.infura.io/v3/" + process.env.INFURA_KEY,
    },
    {
      id: "0x4",
      token: "rETH",
      label: "Rinkeby Testnet",
      rpcUrl: "https://rinkeby.infura.io/v3/" + process.env.INFURA_KEY,
    },
  ],
  appMetadata: {
    name: "0xBears",
    icon: "/logo.jpeg",
    description: "🐻10000 Collections of 0xBears 🐻.\r\rPure DEGEN 💛\r\nNot affiliated with any projects 🟨.",
    recommendedInjectedWallets: [
      { name: "MetaMask", url: "https://metamask.io" },
      { name: "Coinbase", url: "https://wallet.coinbase.com/" },
    ],
  },
});

interface ButtonProps {
  className?: string;
  children?: any;
  disable?: boolean;
  onClick: () => void;
}

function Button(props: ButtonProps) {
  return (
    <div
      className={`block text-center text-amber-700 font-bold uppercase bg-amber-200 hover:bg-amber-50 transition-all transition-duration-200 leading-none ${
        props.className
      } align-middle select-none flex flex-row items-center justify-center border-amber-700 border-2 ${
        props.disable ? "" : "cursor-pointer"
      }`}
      onClick={props.disable ? undefined : props.onClick}
    >
      {props.children}
    </div>
  );
}

function shortenAddress(address: string): string {
  return address.substring(0, 6) + "..." + address.substring(38, 42);
}

const saleConfigDefaultValue = {
  price: BigNumber.from(0),
  maxSupply: 0,
  transactionLimit: 0,
};

const saleStateDefaultValue = {
  soldOut: false,
  saleStarted: false,
  totalSupply: 0,
};

export default function MintBox() {
  const [notify, setNotify] = useState<ReturnType<typeof Notify>>();
  const [{ wallet, connecting }, connect] = useConnectWallet();
  const [{ connectedChain, settingChain }, setChain] = useSetChain();
  const correctChain = useMemo(() => !connectedChain || connectedChain.id === process.env.CHAIN_ID, [connectedChain]);
  const [confirmingTx, setConfirmingTx] = useState<boolean>(false);

  const [amount, setAmount] = useState<number>(5);

  const [saleConfig, setSaleConfig] = useState(saleConfigDefaultValue);
  const [saleState, setSaleState] = useState(saleStateDefaultValue);

  const contract = useMemo(() => {
    const contractAddress = process.env.CONTRACT_ADDRESS;
    if (!wallet || !correctChain) {
      setSaleState(saleStateDefaultValue);
      return undefined;
    }

    const provider = new ethers.providers.Web3Provider(wallet.provider as any);
    const c = ZeroExBears__factory.connect(contractAddress as string, provider);

    Promise.all([c._price(), c._maxSupply(), c._transactionLimit(), c._saleStarted(), c.totalSupply()])
      .then(([price, maxSupply, transactionLimit, saleStarted, totalSupply]) => {
        const config = {
          price,
          maxSupply: maxSupply.toNumber(),
          transactionLimit: transactionLimit.toNumber(),
        };
        const state = {
          soldOut: totalSupply.gte(maxSupply),
          saleStarted,
          totalSupply: totalSupply.toNumber(),
        };

        setSaleConfig(config);
        setSaleState(state);
      })
      .catch((error) => {
        console.error("Fail to get sale config:", error);
        setSaleConfig(saleConfigDefaultValue);
        setSaleState(saleStateDefaultValue);
      });

    return c;
  }, [wallet, correctChain]);

  const increaseAmount = useCallback(() => {
    setAmount((a) => (saleConfig.transactionLimit > a ? a + 1 : a));
  }, [saleConfig]);

  const decreaseAmount = useCallback(() => {
    setAmount((a) => (a > 1 ? a - 1 : a));
  }, []);

  const updateSaleState = useCallback(async () => {
    if (!contract) {
      setSaleState(saleStateDefaultValue);
      return;
    }

    const [saleStarted, totalSupply] = await Promise.all([contract._saleStarted(), contract.totalSupply()]);

    setSaleState({
      soldOut: totalSupply.gte(saleConfig.maxSupply),
      saleStarted,
      totalSupply: totalSupply.toNumber(),
    });
  }, [contract, saleConfig]);

  const mint = useCallback(async () => {
    if (!wallet) {
      return;
    }

    const provider = new ethers.providers.Web3Provider(wallet.provider as any);

    const signer = provider?.getSigner(wallet?.accounts[0].address);
    if (!signer) {
      notify?.notification({
        type: "error",
        message: "There is no valid signer",
      });
      return;
    }

    let tx: TransactionResponse;

    try {
      tx = await contract!
        .connect(provider.getSigner())
        .mintBears(amount, { value: BigNumber.from(amount).mul(saleConfig.price) });
    } catch (error) {
      let err = error as any;
      notify?.notification({
        type: "error",
        message: "Fail to send transaction.<br />Code: " + err.code + "<br />Message: " + err.message,
        autoDismiss: 5 * 1000,
      });
      return;
    } finally {
      setConfirmingTx(false);
    }

    const txSentNotification = notify?.notification({
      type: "pending",
      message: "Transaction sent",
    });

    const txRecipient = await tx.wait();

    if (txRecipient.status === 1) {
      txSentNotification?.update({
        type: "success",
        message: "Successfully adopted!",
        autoDismiss: 5000,
      });
    } else {
      txSentNotification?.update({
        type: "error",
        message: "Transaction failed",
        autoDismiss: 5000,
        // link: txLink,
      });
    }
  }, [contract, saleConfig, wallet, notify, amount]);

  useEffect(() => {
    const intervalId = setInterval(() => updateSaleState(), 1000);

    return () => clearInterval(intervalId);
  }, [updateSaleState]);

  useEffect(() => {
    setNotify(Notify({ mobilePosition: "bottom", desktopPosition: "topLeft" }));
  }, []);

  return (
    <div className="absolute top-0 left-0 w-full h-full z-10 px-24 flex flex-row items-center gap-32">
      {!(wallet?.accounts[0] && correctChain) && (
        <div className="w-6/12 flex flex-col items-center">
          {correctChain ? (
            <Button className="h-16 w-64 rounded-md text-xl" onClick={() => connect({})}>
              {connecting ? "Connecting" : <i>Connect Wallet</i>}
            </Button>
          ) : (
            <Button
              className="h-16 w-64 rounded-md text-xl"
              onClick={() => setChain({ chainId: process.env.CHAIN_ID! })}
            >
              {settingChain ? "Switching" : <i>Switch Network</i>}
            </Button>
          )}
        </div>
      )}

      {wallet?.accounts[0] && correctChain && (
        <div className="w-6/12 flex flex-col items-center">
          {/* Description */}
          <div className="pb-4 font-bold text-md text-amber-700 text-3xl text-center leading-tight select-none">
            <div>Adopt your 0xBears now!</div>
            <div>Instant Reveal</div>
            <div>!! PURE DEGEN !!</div>
            <div>0.015Ξ each</div>
            <div>10 mints per wallet</div>

            <br />
          </div>

          <div className="text-amber-700 py-2 font-semibold text-md">
            {saleConfig.maxSupply - saleState.totalSupply}/{saleConfig.maxSupply} LEFT
          </div>

          {/* Input */}
          <div className="mb-4 flex flex-row items-center justify-center gap-4">
            <div
              className={
                "w-8 h-8 bg-amber-800 hover:bg-amber-200 transition-all transition-duration-300 rounded-circle flex justify-center items-center " +
                (saleState.saleStarted ? "cursor-pointer" : "")
              }
              onClick={saleState.saleStarted ? decreaseAmount : undefined}
            >
              <FontAwesomeIcon icon={faMinus} color="white" />
            </div>
            <input
              disabled
              className="w-16 h-12 rounded-md text-center text-amber-700 bg-amber-200 select-none"
              type="text"
              value={amount}
              readOnly
            />
            <div
              className={
                "w-8 h-8 bg-amber-800 hover:bg-amber-200 transition-all transition-duration-300 rounded-circle flex justify-center items-center " +
                (saleState.saleStarted ? "cursor-pointer" : "")
              }
              onClick={saleState.saleStarted ? increaseAmount : undefined}
            >
              <FontAwesomeIcon icon={faPlus} color="white" />
            </div>
          </div>

          <Button
            className="h-12 w-48 rounded-md text-xl"
            onClick={mint}
            disable={!saleState.saleStarted || saleState.soldOut}
          >
            {!saleState.saleStarted && <>Not Started</>}
            {saleState.soldOut && <>Sold Out</>}
            {saleState.saleStarted && !saleState.soldOut && (
              <>Mint for {ethers.utils.formatEther(saleConfig.price.mul(amount))}Ξ</>
            )}
          </Button>

          <div className="py-4 text-md text-amber-700 select-none">
            Connected as: {shortenAddress(wallet?.accounts[0].address)}
          </div>
        </div>
      )}

      <div className="flex flex-col">
        <div className="uppercase italic text-amber-700 font-bold text-8xl select-none">
          <div>Adopt</div>
          <div>Your</div>
          <div>Bears!</div>
        </div>
      </div>
    </div>
  );
}
