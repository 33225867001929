import { createRef, useEffect, useRef, useState } from "react";

interface Props {
  interval?: number;
  images: string[];
}

export default function Gallery(props: Props) {
  return (
    <div className="absolute top-0 left-0 h-full w-full flex flex-row items-center opacity-80 gap-8 overflow-x-scroll">
      {props.images.map((img, idx) => (
        <img key={idx} className="w-96 h-96 border-2 border-amber-800 shadow-xl" src={img} alt="" />
      ))}
    </div>
  );
}
